var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "settings_carpooling_details" }
    },
    [
      _c("inner-page-title", {
        attrs: { title: "Carpooling info", action: "Save" },
        on: { actionClicked: _vm.onSaveProfile }
      }),
      _c(
        "div",
        { staticClass: "container", staticStyle: { "max-width": "600px" } },
        [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "settings-option",
                  staticStyle: { "padding-left": "0", "padding-right": "0" }
                },
                [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "action-icon" },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#ffa140" },
                        model: {
                          value: _vm.user.carpooling_details.available,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.user.carpooling_details,
                              "available",
                              $$v
                            )
                          },
                          expression: "user.carpooling_details.available"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm.user.carpooling_details.available
                ? _c(
                    "form-item",
                    { attrs: { label: "Role" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "I want to be a..." },
                          on: { change: _vm.changeCarpoolingRole },
                          model: {
                            value: _vm.temp.role,
                            callback: function($$v) {
                              _vm.$set(_vm.temp, "role", $$v)
                            },
                            expression: "temp.role"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: "Passenger only",
                              value: "passenger"
                            }
                          }),
                          _c("el-option", {
                            attrs: { label: "Driver only", value: "driver" }
                          }),
                          _c("el-option", {
                            attrs: {
                              label: "Either passenger or driver",
                              value: "both"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.carpooling_details.available
                ? _c(
                    "form-item",
                    { attrs: { label: "About me" } },
                    [
                      _c("el-input", {
                        staticClass: "w100",
                        attrs: {
                          autosize: "",
                          rows: 3,
                          type: "textarea",
                          placeholder:
                            "What do you think your future carpooling buddy would like to know about you? Do you want to share the cost of the petrol? If so, how much?"
                        },
                        model: {
                          value: _vm.user.carpooling_details.description,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.user.carpooling_details,
                              "description",
                              $$v
                            )
                          },
                          expression: "user.carpooling_details.description"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.carpooling_details.available &&
              _vm.user.carpooling_details.driver
                ? _c(
                    "form-item",
                    { attrs: { label: "Car registration plate" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "191 D 1234" },
                        model: {
                          value: _vm.user.car_reg_plate,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "car_reg_plate", $$v)
                          },
                          expression: "user.car_reg_plate"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.carpooling_details.available &&
              _vm.user.carpooling_details.driver
                ? _c(
                    "form-item",
                    {
                      attrs: {
                        label: "Wanted contribution in euros (max €2.50)"
                      }
                    },
                    [
                      _c("el-input-number", {
                        staticClass: "w100",
                        attrs: {
                          precision: 2,
                          step: 0.1,
                          max: 2.5,
                          min: 0,
                          placeholder: "Petrol contribution when driving"
                        },
                        model: {
                          value: _vm.user.carpooling_details.contribution,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.user.carpooling_details,
                              "contribution",
                              $$v
                            )
                          },
                          expression: "user.carpooling_details.contribution"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-name" }, [
      _c("span", [_vm._v("Available to carpool")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }