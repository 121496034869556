<template>
  <div v-loading.fullscreen.lock="loading" id="settings_carpooling_details">
		<inner-page-title title="Carpooling info" action="Save" @actionClicked="onSaveProfile"></inner-page-title>
		<div style="max-width: 600px" class="container">
			<div>
				<div class="settings-option" style="padding-left: 0; padding-right: 0">
					<!-- <div class="icon"><font-awesome-icon :icon="['fas', 'check-square']"></font-awesome-icon></div> -->
					<div class="info-name"><span>Available to carpool</span></div>
					<div class="action-icon">
						<el-switch active-color="#ffa140" v-model="user.carpooling_details.available"></el-switch>
					</div>
				</div>

				<form-item v-if="user.carpooling_details.available" label="Role">
					<el-select @change="changeCarpoolingRole" v-model="temp.role" placeholder="I want to be a...">
						<el-option label="Passenger only" value="passenger"></el-option>
						<el-option label="Driver only" value="driver"></el-option>
						<el-option label="Either passenger or driver" value="both"></el-option>
					</el-select>
				</form-item>

				<form-item v-if="user.carpooling_details.available" label="About me">
					<el-input class="w100" autosize :rows="3" type="textarea" placeholder="What do you think your future carpooling buddy would like to know about you? Do you want to share the cost of the petrol? If so, how much?" v-model="user.carpooling_details.description"></el-input>
				</form-item>


				<form-item v-if="user.carpooling_details.available && user.carpooling_details.driver" label="Car registration plate">
					<el-input placeholder="191 D 1234" v-model="user.car_reg_plate"></el-input>
				</form-item>

				<form-item v-if="user.carpooling_details.available && user.carpooling_details.driver" label="Wanted contribution in euros (max €2.50)">
					<el-input-number :precision="2" :step="0.1" :max="2.5" :min="0" placeholder="Petrol contribution when driving" class="w100" v-model="user.carpooling_details.contribution"></el-input-number>
				</form-item>
			</div>
		</div>
  </div>
</template>

<script>
import FormItem from '@/components/Form/FormItem';
import firebase from '@/firebase';
import lodash from 'lodash';

export default {
	components: { FormItem },
	data() {
		return {
			user: {},
			loading: false,
			temp: {
				role: ''
			}
		}
	},

	methods: {
		onSaveProfile() {
			let form_data = {
				car_reg_plate: this.user.car_reg_plate,
				carpooling_details: this.user.carpooling_details
			};

			if (!form_data.carpooling_details.contribution) {
				delete form_data.carpooling_details.contribution;
			}

			this.loading = true;

			// Update carpooling details
			this.SharoAPI({ action: 'updateUserProfile', data: form_data }).then(result => {
				console.log(result);

				if (result.data.error) {
					return this.$message({ type: 'error', showClose: true, message: result.data.error_message });
				}

				this.$message({ type: 'success', showClose: true, message: 'Profile updated successfully' });
			}).finally(() => this.loading = false);
		},

		changeCarpoolingRole(val) {
			if (val == 'both') {
				this.user.carpooling_details.driver = true;
				this.user.carpooling_details.passenger = true;
			} else if (val == 'passenger') {
				this.user.carpooling_details.driver = false;
				this.user.carpooling_details.passenger = true;
			} else if (val == 'driver') {
				this.user.carpooling_details.driver = true;
				this.user.carpooling_details.passenger = false;
			}
		}
	},

	computed: {
		_user() {
			return this.$store.getters.user;
		}
	},

	watch: {
		_user: {
			handler(val) {
				this.user = lodash.cloneDeep(val.data || { address: {}, carpooling_details: {}});
				this.temp.role = this.user.carpooling_details.driver && this.user.carpooling_details.passenger ? 'both' : (this.user.carpooling_details.driver ? 'driver' : (this.user.carpooling_details.passenger ? 'passenger' : ''));
			},
			immediate: true
		}
	},

	async mounted() {
		
	}
}
</script>

<style lang="scss" scoped>
	#settings_carpooling_details {
		.form-item {
			// margin-bottom: 0;
			margin-top: 15px;
		}

		.profile-image-container {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 15px;

			img {
				border-radius: 50%;
				width: 100%;
				max-width: 120px;
				opacity: 0.3;
			}

			.upload-image {
				position: absolute;
			}
		}
	}
</style>